import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "login",
    meta: { title: "登录" },
    component: () => import("../views/login.vue"),
  },
  {
    path: "/home",
    name: "Home",
    meta: { title: "首页", roles: ["dept", "listen_admin", "student"] },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/home.vue"),
    children: [
      {
        path: "/main",
        name: "Main",
        meta: { title: "系统首页", roles: ["dept", "listen_admin", "student"] },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/main.vue"),
      },
      {
        path: "/living",
        name: "Living",
        meta: { title: "在线直播", roles: ["dept", "listen_admin", "student"] },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/living.vue"),
      },
      {
        path: "/select",
        name: "Select",
        meta: {
          title: "督导听课",
          roles: ["dept", "listen_admin"],
        },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/select.vue"),
      },
      {
        path: "/preview",
        name: "Preview",
        meta: { title: "直播页面", roles: ["dept", "listen_admin", "student"] },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/preview.vue"),
      },
      {
        path: "/video",
        name: "Video",
        meta: { title: "回放页面", roles: ["dept", "listen_admin", "student"] },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/video.vue"),
      },
      {
        path: "/dudao",
        name: "Dudao",
        meta: { title: "督导直播", roles: ["dept", "listen_admin"] },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/dudao.vue"),
      },
      {
        path: "/classroom",
        name: "Classroom",
        meta: { title: "教室管理", roles: ["dept", "listen_admin"] },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/classroom.vue"),
      },
      {
        path: "/room",
        name: "room",
        meta: { title: "安全巡检", roles: ["dept", "listen_admin"] },
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/room.vue"),
      },
      {
        path: "/devices",
        name: "devices",
        meta: { title: "设备信息", roles: ["dept", "listen_admin"] },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/devices.vue"),
      },
      {
        path: "/addcamera",
        name: "addcamera",
        meta: { title: "添加设备", roles: ["dept", "listen_admin"] },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/addcamera.vue"),
      },
      {
        path: "/updatacamera",
        name: "updatacamera",
        meta: { title: "修改设备", roles: ["dept", "listen_admin"] },
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/updatacamera.vue"),
      },
    ],
  },
  {
    path: "/monitor",
    name: "Monitor",
    meta: { title: "安全巡检", roles: ["dept", "listen_admin"] },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/monitor.vue"),
  },
];

// 创建了一个新的VueRouter实例，传入了routes参数，这里的routes是路由配置数组，用于定义应用程序的路由。
const router = new VueRouter({
  routes,
});

// 将该VueRouter实例导出为默认模块，使其他地方可以引入并使用该实例。
export default router;

// 这段代码重写了Vue Router的push方法。原本的push方法在路由跳转时，如果目标路由不存在，则会抛出错误。而经过重写后的push方法，在目标路由不存在时，不再抛出错误，而是通过catch捕获错误信息，并返回错误对象。
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};

// 这部分代码是路由守卫的配置，使用beforeEach方法定义了一个全局前置守卫。在每次路由导航之前，会执行传入的回调函数。
// 首先，通过localStorage.getItem("userRoles")获取用户角色信息，并解析成对象。
// 如果用户角色为空或未定义，则直接放行，即执行next()跳转到下一个钩子或导航。
// 如果目标路由（to）定义了需要的角色（to.meta.roles），则进一步判断用户角色是否包含目标路由所需的任一角色。
// 如果用户拥有所需角色之一，则放行，即执行next()跳转到下一个钩子或导航。
// 如果用户没有所需角色，则将导航重定向到"/login"路径。
router.beforeEach((to, from, next) => {
  const userRoles = JSON.parse(localStorage.getItem("userRoles")); // 假设用户有两个角色：'user'和'admin'
  if (userRoles == null || userRoles == "") {
    next();
  } else if (to.meta.roles && to.meta.roles.length > 0) {
    // 判断目标路由是否定义了需要的角色
    const requiredRoles = to.meta.roles;
    // 判断用户的角色是否包含目标路由所需的任一角色
    const hasRequiredRole = userRoles.some((role) =>
      requiredRoles.includes(role)
    );
    if (hasRequiredRole) {
      // 用户拥有所需角色之一，放行
      next();
    } else {
      // 用户没有所需角色，跳转到404页面或其他未授权页面
      next({ path: "/login" });
    }
  } else {
    // 目标路由未定义需要的角色，放行
    next();
  }
});
