import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    username: "",
  },
  mutations: {
    loginChange(state, user) {},
  },
});
export default store;
