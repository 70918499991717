import Vue from "vue";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.prototype.axios = axios; //全局注册，使用方法为:this.$axios
import router from "./router";
import store from "./store";
import ElementUI, { Message } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Vuex from "vuex";
Vue.use(ElementUI);
Vue.use(Vuex);
Vue.use(router);

Vue.use(VueAxios, axios);
Vue.config.productionTip = false;

// 导航守卫
// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    next();
  } else {
    let token = window.localStorage.flagToken;
    if (token === "null" || token === "" || token === undefined) {
      next("/login");
    } else {
      next();
    }
  }
});
// 添加响应拦截器
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.data.code == 402) {
      Vue.prototype.$alert("登录过期请重新登录", "提示", {
        confirmButtonText: "确定",
        type: "warning",
        center: true,
        callback: (action) => {
          localStorage.removeItem("flagToken");
          router.replace({
            path: "/login",
          });
        },
      });
    }
    return error.response;
  }
);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
